import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Home.module.scss';
import FooterOne from "./common/footers/footer-one";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {apiCall} from "../helpers/networkHelper";
import {MASTER_PAGE_HOME} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {empty} from "../helpers/generalHelper";
import ModuleNewsletter from "./ModuleNewsletter";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homeData: null,
            categories: null,
            mobileMenuOpened: false
        }
    }

    componentDidMount() {
        document.getElementById("loader-wrapper").style = "display: flex";
        document.getElementById("loader").style = "display: block";

        apiCall(
            MASTER_PAGE_HOME,
            'get',
            null,
            res => {
                this.setState({
                    homeData: res.data.home_data,
                    categories: res.data.categories
                }, () => {
                    document.getElementById("loader-wrapper").style = "display: none";
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                // this.hideLoader();
            }
        );
        // setTimeout(function () {
        //     document.getElementById("loader-wrapper").style = "display: none";
        // }, 2000);
    }

    render() {
        const {homeData} = this.state;
        const sliderSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            swipe: false,
            pauseOnHover: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 8000,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Hammer | PT Warna Mardhika</title>
                    <meta name="description"
                          content="HAMMER was launched as the first brand of PT WARNA MARDHIKA in 1987. With characteristic lines, varying colors and bold, making HAMMER very famous among celebrities, models, and Indonesians."/>
                </Helmet>

                {/*<div style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    overflow: "auto",
                    background: 'white',
                    margin: 0,
                    padding: 0,
                    zIndex: 100
                }}>TEST</div>*/}

                <section className={styles.sliderSection}>
                    <Slider {...sliderSettings} className={styles.slider}>
                        {/*<div className="item youtube">
                            <iframe className="embed-player slide-media"
                                    src="https://www.youtube.com/embed/-MNrE89FZQE?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=-MNrE89FZQE&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                            />
                        </div>*/}

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-1.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-1-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        {/*<div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-2.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-2-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>*/}

                        {/*<div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-3.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-3-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>*/}

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-4.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-4-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-5.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-5-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-6.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-6-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        {/*<div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-3.jpeg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-3-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-4.jpeg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-4-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>*/}

                        {/*<div className="item youtube">
                            <iframe className="embed-player slide-media"
                                    src="https://www.youtube.com/embed/WxV3HF7hIQ4?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=WxV3HF7hIQ4&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                            />
                        </div>*/}
                    </Slider>
                </section>

                <section className={styles.sliderSectionMobile}>
                    <Slider {...sliderSettings} className={styles.slider}>
                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-1.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-1-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        {/*<div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-2.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-2-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>*/}

                        {/*<div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-3.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-3-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>*/}

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-4.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-4-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-5.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-5-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-6.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/home-slider-6-mobile.jpg`} alt="Hammer"/>
                                )}
                            </Link>
                        </div>

                        {/*<div className="item youtube">
                            <iframe className="embed-player slide-media"
                                    src="https://www.youtube.com/embed/WxV3HF7hIQ4?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=WxV3HF7hIQ4&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                            />
                        </div>*/}
                    </Slider>
                </section>

                {false && (
                <>
                <section className={styles.sliderSection}>
                    <Slider {...sliderSettings} className={styles.slider}>
                        <div className="item image">
                            <Link to="/products">
                                {(window.innerWidth > 991) ? (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-1a.jpg`} alt="Hammer"/>
                                ) : (
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-1-mobile.jpg`} alt="Hammer"/>
                                )}
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-1a.jpg`} alt="Hammer"/>
                            </Link>
                        </div>
                        <div className="item image">
                            <Link to="/products">
                            {(window.innerWidth > 991) ? (
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-2a.jpg`} alt="Hammer"/>
                            ) : (
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-2-mobile.jpg`} alt="Hammer"/>
                            )}
                            </Link>
                        </div>
                        <div className="item image">
                            <Link to="/products">
                            {(window.innerWidth > 991) ? (
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-3a.jpeg`} alt="Hammer"/>
                            ) : (
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-3-mobile.jpg`} alt="Hammer"/>
                            )}
                            </Link>
                        </div>
                        <div className="item image">
                            <Link to="/products">
                            {(window.innerWidth > 991) ? (
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-4a.jpeg`} alt="Hammer"/>
                            ) : (
                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-slider-4-mobile.jpg`} alt="Hammer"/>
                            )}
                            </Link>
                        </div>
                        <div className="item youtube">
                            <iframe className="embed-player slide-media"
                                    src="https://www.youtube.com/embed/QV5EXOFcdrQ?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=QV5EXOFcdrQ&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                            />
                        </div>
                        <div className="item youtube">
                            <iframe className="embed-player slide-media"
                                    src="https://www.youtube.com/embed/WxV3HF7hIQ4?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&playlist=WxV3HF7hIQ4&start=1&autoplay=1&disablekb=1&mute=1&modestbranding=1&rel=0"
                                    frameBorder="0"
                                    allowFullScreen
                            />
                        </div>
                    </Slider>
                </section>

                <div className={styles.mobileMenu} style={{display: this.state.mobileMenuOpened ? 'block' : 'none'}}>
                    <div className={styles.closeBtn}>
                        <i className="fa fa-remove"
                           onClick={() => {
                               this.setState({
                                   mobileMenuOpened: false
                               });
                           }}
                        />
                    </div>
                    <div className={styles.headerLogo}>
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut.png`}
                                 className="img-fluid" alt="Hammer"/>
                        </Link>
                    </div>

                    <div className={styles.headerMenu}>
                        <ul>
                            <li><Link to={`${process.env.PUBLIC_URL}/shop/men`}>Men</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/shop/women`}>Women</Link></li>
                            <li><a href={`/products?group_id=3`}>Kids</a></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/products`}>Shop All</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/hashtag`}>#iamCOCONUTs</Link></li>
                        </ul>
                    </div>
                </div>

                <section style={{paddingTop: 0}} className={styles.hero}>
                    <div className={styles.fsHeroItem}>
                        <div className={`${styles.fsHeroContentWrapper}`}>
                            <header>
                                <div className={styles.headerMenu}>
                                    <ul>
                                        <li><Link to={`${process.env.PUBLIC_URL}/shop/men`}>Men</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/shop/women`}>Women</Link></li>
                                        <li><a href={`/products?group_id=3`}>Kids</a></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/products`}>Shop All</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/hashtag`}>#iamCOCONUTs</Link></li>
                                    </ul>
                                </div>

                                <div className={styles.headerMenuToggle}>
                                    <i className="fa fa-bars" onClick={() => {
                                        this.setState({
                                            mobileMenuOpened: true
                                        });
                                    }}/>
                                </div>

                                <div className={styles.headerSearch}>
                                    <form action="/products" method="get">
                                        <i className="fa fa-search"/>
                                        <input type="text" name="search" placeholder="Search"/>
                                    </form>
                                </div>

                                <div className={styles.headerLogo}>
                                    <Link to="/">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut.png`}
                                             className="img-fluid" alt="Hammer"/>
                                    </Link>
                                </div>
                            </header>

                            <div className={styles.fsHeroContent}>
                                <h1 className={styles.fsHeroContentTitle}>Last Days of Sale<br/>up to -50%</h1>
                                <Link className={styles.fsHeroContentActionBtn} to="/products">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                </section>
                </>
                )}

                {/*<section style={{paddingTop: 0}} className={styles.hero}>
                    <div className={styles.fsHeroItem}>
                        <div className={styles.fsHeroVideoWrapper}>
                            <video loop muted autoPlay className={styles.fsHeroVideo}>
                                <source src={`${process.env.PUBLIC_URL}/assets/videos/dummy.mp4`} type="video/mp4"/>
                            </video>

                            <img src={`${process.env.PUBLIC_URL}/assets/images/wm/ci/home-1.jpg`} alt="Hammer"/>
                        </div>

                        <div className={`${styles.fsHeroContentWrapper}`}>
                            <header>
                                <div className={styles.headerMenu}>
                                    <ul>
                                        <li><Link to={`${process.env.PUBLIC_URL}/shop/men`}>Men</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/shop/women`}>Women</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/products`}>Shop All</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/hashtag`}>#iamCOCONUTs</Link></li>
                                    </ul>
                                </div>

                                <div className={styles.headerSearch}>
                                    <i className="fa fa-search"/>
                                    <input type="text" placeholder="Search"/>
                                </div>

                                <div className={styles.headerLogo}>
                                    <Link to={`${process.env.PUBLIC_URL}/products`}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut.png`}
                                             className="img-fluid" alt="Hammer"/>
                                    </Link>
                                </div>
                            </header>

                            <div className={styles.fsHeroContent}>
                                <h1 className={styles.fsHeroContentTitle}>Last Days of Sale<br/>up to -50%</h1>
                                <Link className={styles.fsHeroContentActionBtn} to="/products">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                </section>*/}

                <div style={{position: 'sticky', top: 0}}>
                    <section className={styles.row1}>
                        <div className={styles.col1}>
                            <img src={homeData?.HOME_SECTION_1_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_1_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_1_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_1_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_1_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_1_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_1_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_1_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_1_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                    </section>

                    <section className={styles.row2}>
                        <div className={styles.col1}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_2_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_2_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_2_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_2_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_2_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_2_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_2_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_2_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                        <div className={styles.col2}>
                            <img src={homeData?.HOME_SECTION_2_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                    </section>

                    <section className={styles.row1}>
                        <div className={styles.col1}>
                            <img src={homeData?.HOME_SECTION_3_IMAGE_DESKTOP} alt="Banner"/>
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.thinTitle}>
                                {homeData?.HOME_SECTION_3_TITLE_LINE_1}
                            </div>
                            <div className={styles.thickTitle}>
                                {homeData?.HOME_SECTION_3_TITLE_LINE_2}
                            </div>
                            <div className={styles.subtitle}>
                                {homeData?.HOME_SECTION_3_SUBTITLE}
                            </div>

                            {!empty(homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT) && (
                                <div>
                                    {homeData?.HOME_SECTION_3_ACTION_BUTTON_NEW_TAB === 1 && (
                                        <a href={homeData?.HOME_SECTION_3_ACTION_BUTTON_URL}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT}</a>
                                    )}

                                    {homeData?.HOME_SECTION_3_ACTION_BUTTON_NEW_TAB !== 1 && (
                                        <a href={homeData?.HOME_SECTION_3_ACTION_BUTTON_URL}
                                           className={styles.actionBtn}
                                        >{homeData?.HOME_SECTION_3_ACTION_BUTTON_TEXT}</a>
                                    )}

                                </div>
                            )}
                        </div>
                    </section>

                    <section className={styles.hashtagSection}>
                        <h2><Link to='/hashtag'>#ootdHAMMER</Link></h2>
                        <p>
                            Share your personal style and see how others are styling their favourites from Hammer.<br/>Tag
                            your picture with @hammer_brand and #ootdHAMMER for a chance to be featured on hammer-clothing.com
                            and in our stores.
                        </p>
                        <div className={styles.hashtagPhotos}>
                            <div className={styles.hashtagPhoto}>
                                <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag01.jpeg`}
                                         alt="HashTag Photo"/>
                                    <div className={styles.hashtagPhotoOverlay}>
                                        <div className={styles.hashtagPhotoText}>
                                            @hammer_brand
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className={styles.hashtagPhoto}>
                                <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag09.jpeg`}
                                         alt="HashTag Photo"/>
                                    <div className={styles.hashtagPhotoOverlay}>
                                        <div className={styles.hashtagPhotoText}>
                                            @hammer_brand
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className={styles.hashtagPhoto}>
                                <a href="https://www.instagram.com/hammer_brand/" target="_blank" rel="noopener noreferrer">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/wm/hm/hashtag/hashtag06.jpeg`}
                                         alt="HashTag Photo"/>
                                    <div className={styles.hashtagPhotoOverlay}>
                                        <div className={styles.hashtagPhotoText}>
                                            @hammer_brand
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </section>

                    <ModuleNewsletter />

                    {/*<FooterOne logoName={'logo.png'}/>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Home;